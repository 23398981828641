<template>
  <div class="cloudClinic">
    <Head :classA="'1'"></Head>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <div class="swiperFirstLeft">
              <h3><strong>致医</strong>云诊室</h3>
              <p>
                基层诊所医生的
                <strong>业务利器</strong>
              </p>
              <div @click="showJoin" class="trialBtn">点击申请试用</div>
            </div>
            <img src="@/assets/images/cloudClinic/bannerbg2x.png" alt />
          </div>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <div class="main">
      <div class="cloudClinicTop">
        <h3>一站式全流程医生工作台</h3>
        <div class="swiperTop">
          <ul class="tabTitle">
            <li @click="tabId = 0" :class="tabId === 0 ? 'active' : ''">
              预约就诊
            </li>
            <li @click="tabId = 1" :class="tabId === 1 ? 'active' : ''">
              处方管理
            </li>
            <li @click="tabId = 2" :class="tabId === 2 ? 'active' : ''">
              零售卖药
            </li>
            <li @click="tabId = 3" :class="tabId === 3 ? 'active' : ''">
              药房管理
            </li>
            <li @click="tabId = 4" :class="tabId === 4 ? 'active' : ''">
              患者管理
            </li>
            <li @click="tabId = 5" :class="tabId === 5 ? 'active' : ''">
              经营分析
            </li>
            <li @click="tabId = 6" :class="tabId === 6 ? 'active' : ''">
              专家咨询
            </li>
            <li @click="tabId = 7" :class="tabId === 7 ? 'active' : ''">
              知识课堂
            </li>
          </ul>
          <div class="tabBox">
            <div class="tabCon" v-show="tabId === 0">
              <div class="tabLeft tab0Left">
                <img
                  class="tab0img"
                  src="../../assets/images/cloudClinic/tab0.png"
                  width="517"
                />
                <img
                  class="tabbg tab0bg"
                  src="../../assets/images/cloudClinic/tab0bg.png"
                  width="223"
                />
              </div>
              <dl class="tabRight tab0Right">
                <dt>预约就诊</dt>
                <dd>多种挂号方式，简单快捷</dd>
                <dd>患者提前输入信息，医生轻松，就诊高效</dd>
                <dd>诊所自定义号源数量及时间，合理安排就诊</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 1">
              <div class="tabLeft tab1Left">
                <img
                  src="../../assets/images/cloudClinic/tab1.png"
                  width="517"
                />
                <img
                  class="tabbg tab1bg"
                  src="../../assets/images/cloudClinic/tab1bg.png"
                  width="360"
                />
              </div>
              <dl class="tabRight tab1Right">
                <dt>处方管理</dt>
                <dd>常用处方、经典成方一键引用，分组管理</dd>
                <dd>历史处方一键复制，老病号看病更高效</dd>
                <dd>欠费记录一目了然，避免诊所损失</dd>
                <dd>病史、过敏史提示，降低诊疗风险</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 2">
              <div class="tabLeft tab2Left">
                <img
                  src="../../assets/images/cloudClinic/tab2.png"
                  width="519"
                />
              </div>
              <dl class="tabRight tab2Right">
                <dt>零售卖药</dt>
                <dd>一扫即卖，药品销售更便捷</dd>
                <dd>销售流向管理，出库更规范</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 3">
              <div class="tabLeft tab3Left">
                <img
                  src="../../assets/images/cloudClinic/tab3.png"
                  width="517"
                />
                <img
                  class="tabbg tab3bg"
                  src="../../assets/images/cloudClinic/tab3bg.png"
                  width="124"
                />
              </div>
              <dl class="tabRight tab3Right">
                <dt>药房管理</dt>
                <dd>30余万基层常用药品数据库，99.5%准确率，业界领先</dd>
                <dd>药品过期、失效提前预警，避免损失</dd>
                <dd>药品库存量不足，及时提醒补货</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 4">
              <div class="tabLeft tab4Left">
                <img
                  src="../../assets/images/cloudClinic/tab4.png"
                  width="517"
                />
              </div>
              <dl class="tabRight tab4Right">
                <dt>患者管理</dt>
                <dd>患者电子档案，管理清晰明了</dd>
                <dd>诊所公告及时推送，营销信息精准触达</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 5">
              <div class="tabLeft tab5Left">
                <img
                  src="../../assets/images/cloudClinic/tab5.png"
                  width="517"
                />
                <img
                  class="tabbg tab5bg1"
                  src="../../assets/images/cloudClinic/tab5bg1.png"
                  width="140"
                />
                <img
                  class="tabbg tab5bg2"
                  src="../../assets/images/cloudClinic/tab5bg2.png"
                  width="184"
                />
              </div>
              <dl class="tabRight tab5Right">
                <dt>经营分析</dt>
                <dd>合规化进销存管理，轻松应对检查</dd>
                <dd>随时查看诊所经营状况，轻松管理绩效</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 6">
              <div class="tabLeft tab6Left">
                <img
                  src="../../assets/images/cloudClinic/tab6.png"
                  width="517"
                />
              </div>
              <dl class="tabRight tab6Right">
                <dt>专家咨询</dt>
                <dd>专家图文问诊，在线答疑</dd>
                <dd>权威专家三方会诊，提升医术</dd>
              </dl>
            </div>
            <div class="tabCon" v-show="tabId === 7">
              <div class="tabLeft tab7Left">
                <img
                  src="../../assets/images/cloudClinic/tab7.png"
                  width="517"
                />
              </div>
              <dl class="tabRight tab7Right">
                <dt>知识课堂</dt>
                <dd>免费观看专家医学视频课堂</dd>
                <dd>医学、药学百科随时查阅</dd>
                <dd>行业资讯、医疗政策动态及时了解</dd>
                <dd>海量教学课程，操作不用愁</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudClinicMiddle">
      <div class="cloudClinicMiddleMain">
        <h3>硬件套餐</h3>
        <swiper :options="swiperOption1" ref="mySwiper1">
          <!-- slides -->
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=3ngmlrva0kte1&activity_alias=undefined"
              >
                <h6>基层诊所标配套餐</h6>
                <p>一体式医生工作台T2</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwBiaozhuntaocan.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=2g0a3j5saeqsp&activity_alias=undefined"
              >
                <h6>基层诊所高端套餐</h6>
                <p>一体式医生工作台T2+致医云屏+快速挂号机</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwJicenggaoduantaocan.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=365bq2yj3mvqh&activity_alias=undefined"
              >
                <h6>致医云屏</h6>
                <p>会叫号的广告屏</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwYunping.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              、
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=35xyv26iloqhl&activity_alias=undefined"
              >
                <h6>致医挂号机</h6>
                <p>一键取号 快速挂号</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwGuahaoji.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=2xmnqjy6mogux&activity_alias=undefined"
              >
                <h6>致医智能血压计</h6>
                <p>实时共享家人数据</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwZhinengxueyaji.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=3eo35ypphjrzd&activity_alias=undefined"
              >
                <h6>周边互联智能设备</h6>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudClinic/hwZhoubianshebei.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev1"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next1"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="cloudClinicBottomWrap">
      <div class="cloudClinicBottom">
        <h3>他们都在用云诊室</h3>
        <swiper :options="swiperOption2" ref="mySwiper2">
          <!-- slides -->
          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudClinic/clinic2.png" alt />
              <p class="clinicTitle">莒县卢西花诊所</p>
              <div class="clinicText">
                <h6>莒县卢西花诊所</h6>
                <p>
                  莒县卢西花诊所主要诊疗科目为内科，诊所门诊量很大。
                  在致医云诊室、云屏与挂号机的系列产品的帮助下，解决了诊所秩序杂乱、诊疗效率低的问题。患者可通过网上预约挂号、现场扫码挂号等形式就诊，使患者的就诊体验有所升级。
                </p>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudClinic/clinic3.png" alt />
              <p class="clinicTitle">南环办事处皇家一里社区卫生服务站</p>
              <div class="clinicText">
                <h6>南环办事处皇家一里社区卫生服务站</h6>
                <p>
                  运河区南环办事处皇家一里社区卫生服务站位于河北省沧州市，主要开设口腔科、中医理疗科、儿科等科室。
                  在致医云诊室的帮助下，解决了诊所医护沟通不畅的问题。诊所医生使用平板看病开方，护士使用一体机收费取药，协同配合，使诊所医疗程序迅速进入流程化。
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudClinic/clinic1.png" alt />
              <p class="clinicTitle">田郎中中医诊所</p>
              <div class="clinicText">
                <h6>田郎中中医诊所</h6>
                <p>
                  田郎中中医诊所位于山东省淄博市，专门从事中医诊所经营。
                  在致医云诊室的中药开方功能帮助下，解决了传统手写处方不易存储、不易查阅的问题，建立了常规化的处方模版及完善的患者个人档案，使诊所的工作效率有所提升。
                </p>
              </div>
            </div>
          </swiper-slide>

          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev2"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next2"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="bottomWrap">
      <p class="title">成为致医云诊室官方代理</p>
      <p class="paragraph">
        助力基层医疗行业发展，提升百万基层医疗机构竞争力，服务数亿基层患者
      </p>
      <span @click="showJoin1" class="joinBtn">立即加盟</span>
    </div>
    <Foot></Foot>

    <JoinBox
      ref="joinBox"
      :companyValid="companyValidVal"
      companyText="医疗机构名称（必填）"
      type="1"
      title="致医云诊室申请试用"
    ></JoinBox>

    <JoinBox
      ref="joinBox1"
      companyText="企业名称（选填）"
      type="0"
      title="申请加盟官方代理"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import '../../assets/style/hardware.css';
</style>
<style lang="scss" scoped>
.cloudClinic {
  background: #fff;
  .swiperFirstWrap {
    width: 100%;
    height: 550px;
    background: linear-gradient(
      161deg,
      rgba(0, 82, 217, 1) 0%,
      rgba(34, 19, 222, 1) 51%,
      rgba(43, 30, 205, 1) 100%
    );
    .swiperFirst {
      width: 79.17%;
      height: 100%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 50%;
        max-width: 353px;
      }
      .swiperFirstLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
      }
      h3 {
        font-size: 36px;
        color: rgba(255, 255, 255, 1);
        line-height: 50px;
        border-bottom: 1px solid #fff;
        strong {
          font-weight: bold;
        }
      }
      p {
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
        line-height: 56px;
        padding: 10px 0 30px 0;
        strong {
          font-weight: bold;
        }
      }
      .trialBtn {
        width: 152px;
        height: 52px;
        line-height: 52px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(43, 30, 205, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    .cloudClinicTop {
      h3 {
        padding: 60px 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      .tabTitle {
        display: flex;
        justify-content: space-between;
        li {
          height: 128px;
          line-height: 30px;
          text-align: center;
          color: #1a1c1f;
          font-size: 18px;
          padding: 82px 25px 21px 25px;
          background-color: rgba(248, 248, 248, 1);
          background-repeat: no-repeat;
          background-position: center 25px;
          border-radius: 20px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
        }
        li:nth-child(1) {
          background-image: url('../../assets/images/cloudClinic/icon1.svg');
        }
        li:nth-child(2) {
          background-image: url('../../assets/images/cloudClinic/icon2.svg');
        }
        li:nth-child(3) {
          background-image: url('../../assets/images/cloudClinic/icon3.svg');
        }
        li:nth-child(4) {
          background-image: url('../../assets/images/cloudClinic/icon4.svg');
        }
        li:nth-child(5) {
          background-image: url('../../assets/images/cloudClinic/icon5.svg');
        }
        li:nth-child(6) {
          background-image: url('../../assets/images/cloudClinic/icon6.svg');
        }
        li:nth-child(7) {
          background-image: url('../../assets/images/cloudClinic/icon7.svg');
        }
        li:nth-child(8) {
          background-image: url('../../assets/images/cloudClinic/icon8.svg');
        }
        li.active {
          color: #fff;
        }
        li.active::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: rgba(43, 30, 205, 1);
          bottom: -19px;
          left: 50%;
          margin-left: -10px;
        }
        li:nth-child(1).active {
          background: url('../../assets/images/cloudClinic/icon1w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(2).active {
          background: url('../../assets/images/cloudClinic/icon2w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(3).active {
          background: url('../../assets/images/cloudClinic/icon3w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(4).active {
          background: url('../../assets/images/cloudClinic/icon4w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(5).active {
          background: url('../../assets/images/cloudClinic/icon5w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(6).active {
          background: url('../../assets/images/cloudClinic/icon6w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(7).active {
          background: url('../../assets/images/cloudClinic/icon7w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
        li:nth-child(8).active {
          background: url('../../assets/images/cloudClinic/icon8w.svg')
              no-repeat center 25px,
            linear-gradient(
              161deg,
              rgba(0, 82, 217, 1) 0%,
              rgba(34, 19, 222, 1) 51%,
              rgba(43, 30, 205, 1) 100%
            );
        }
      }
      .tabBox {
        padding: 80px 0;
        .tabCon {
          display: flex;
          justify-content: space-around;

          .tabLeft {
            width: 50%;
            position: relative;
            z-index: 1;
            img {
              box-shadow: 0 20px 50px 0 rgba(216, 216, 216, 0.7);
            }
            .tabbg {
              position: absolute;
              z-index: 2;
              box-shadow: none;
            }
          }
          .tab0Left {
            .tab0bg {
              left: 82px;
              top: -63px;
            }
          }
          .tab1Left {
            .tab1bg {
              left: -36px;
              bottom: 12px;
            }
          }
          .tab3Left {
            .tab3bg {
              right: 0;
              bottom: -35px;
            }
          }
          .tab5Left {
            .tab5bg1 {
              right: -25px;
              top: -30px;
            }
            .tab5bg2 {
              right: -23px;
              bottom: -45px;
            }
          }
          .tabRight {
            width: 50%;
            text-align: left;
            padding: 43px 0 0 60px;
            box-sizing: border-box;
            dt {
              font-size: 30px;
              color: #1a1c1f;
              padding-bottom: 30px;
              font-weight: bold;
            }
            dd {
              font-size: 22px;
              color: #1a1c1f;
              padding-bottom: 10px;
              position: relative;
              padding-left: 20px;
            }
            dd::before {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              background: rgba(26, 28, 31, 1);
              border-radius: 10px;
              left: 0;
              top: 11px;
            }
          }
        }
      }
    }
  }
  .cloudClinicBottomWrap {
    background: #fff;
  }
  .bottomWrap {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      90deg,
      rgba(0, 82, 217, 1) 0%,
      rgba(34, 19, 222, 1) 52%,
      rgba(43, 30, 205, 1) 100%
    );
    text-align: center;
    margin-top: 80px;
    .title {
      font-size: 32px;
      color: #fff;
      line-height: 45px;
      padding: 63px 0 22px 0;
    }
    .paragraph {
      font-size: 20px;
      color: #fff;
      line-height: 28px;
    }
    .joinBtn {
      display: inline-block;
      width: 160px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #2213de;
      cursor: pointer;
      margin-top: 43px;
      border-radius: 4px;
      background: #fff;
    }
  }
  @media screen and (max-width: 1280px) {
    .main,
    .cloudClinicBottom {
      width: 88%;
    }
  }
  @media screen and (max-width: 1024px) {
    .main {
      width: 94%;
      .cloudClinicTop {
        .tabTitle {
          li {
            font-size: 16px;
          }
        }
        .tabBox .tabCon .tabLeft {
          img {
            width: 100%;
          }
          .tabbg {
            width: 50%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .swiperFirstWrap .swiperFirst p {
      text-align: left;
    }
    .main {
      .cloudClinicTop {
        .tabTitle {
          li {
            height: auto;
            font-size: 16px;
            line-height: 24px;
            padding: 80px 10px 21px 10px;
            background-size: 50%;
          }
          li.active {
            background-size: 50% !important;
          }
        }
        .tabBox {
          .tabCon {
            display: block;
            .tabRight {
              width: 90%;
              margin: 0 auto;
              padding-left: 0;
              dt {
                font-size: 20px;
                color: #1a1c1f;
                padding-bottom: 20px;
              }
              dd {
                font-size: 16px;
                padding-bottom: 6px;
              }
              dd::before {
                width: 6px;
                height: 6px;
                border-radius: 6px;
              }
            }
            .tabLeft {
              width: 90%;
              margin: 0 auto;
              .tabbg {
                width: 30%;
              }
              .tab3bg {
                width: 24%;
              }
            }
            .tab5Left .tab5bg1 {
              right: -60px;
              top: -45px;
            }
            .tab5Left .tab5bg2 {
              right: -54px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'
import JoinBox from '@/components/common/JoinBox'

export default {
  name: 'home',
  components: {
    Head,
    Foot,
    JoinBox
  },
  data () {
    return {
      companyValidVal: true,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        autoplay: false,
        loop: true
      },
      tabId: 0,
      swiperOption1: {
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1'
        },
        autoplay: false,
        loop: true,
        spaceBetween: 0,
        slidesPerView: 3, // 开启自定义slide宽度，配合下面css样式设置即可
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination1',
          clickable: true
        }
      },
      swiperOption2: {
        navigation: {
          nextEl: '.swiper-button-next2',
          prevEl: '.swiper-button-prev2'
        },
        autoplay: false,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination2',
          clickable: true
        }
      }
    }
  },
  methods: {
    tab (index) {
      this.num = index
    },
    showJoin () {
      this.$refs.joinBox.isShowDialog = true
    },
    showJoin1 () {
      this.$refs.joinBox1.isShowDialog = true
    },
    // 鼠标移入加入class
    changeActive ($event) {
      $event.currentTarget.className = 'clinicWrap activeIn'
    },
    removeActive ($event) {
      $event.currentTarget.className = 'clinicWrap activeOut'
    }
  },
  computed: {},
  mounted () {}
}
</script>
